<template>
	<ul class="menu-nav">
		<template v-for="(data, index) in menuList">
			<template v-if="data.child">
				<li
					aria-haspopup="true"
					:key="index"
					data-menu-toggle="hover"
					class="menu-item menu-item-submenu"
					:class="{
						'menu-item-open menu-item-open-active': isRouteActive(data.name),
					}"
				>
					<a href="#" class="menu-link menu-toggle">
						<span class="menu-svg-icon mr-4">
							<inline-svg :src="$assetURL(data.icon)" />
						</span>
						<span class="menu-text">{{ data.text }}</span>
						<i class="menu-arrow"></i>
					</a>
					<div class="menu-submenu">
						<span class="menu-arrow"></span>
						<ul class="menu-subnav">
							<router-link
								v-for="(submenu, submenu_index) in data.submenu"
								:key="`${index}-${submenu_index}`"
								:to="{ name: submenu.name, query: { t: new Date().getTime() } }"
								v-slot="{ href, navigate, isActive, isExactActive }"
							>
								<li
									aria-haspopup="true"
									data-menu-toggle="hover"
									class="menu-item"
									:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
								>
									<a :href="href" class="menu-link" v-on:click="navigate">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ submenu.text }}</span>
									</a>
								</li>
							</router-link>
						</ul>
					</div>
				</li>
			</template>
			<template v-else>
				<template v-if="getPermission">
					<router-link
						:key="index"
						:to="{ name: data.name, query: { t: new Date().getTime() } }"
						v-slot="{ href, navigate, isActive, isExactActive }"
					>
						<li
							aria-haspopup="true"
							data-menu-toggle="hover"
							class="menu-item"
							:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
						>
							<a :href="href" class="menu-link" @click="navigate">
								<span class="menu-svg-icon mr-4">
									<!--begin::Svg Icon-->
									<inline-svg :src="$assetURL(data.icon)" />
									<!--end::Svg Icon-->
								</span>
								<span class="menu-text">{{ data.text }}</span>
							</a>
						</li>
					</router-link>
				</template>
			</template>
		</template>
		<template v-if="false">
			<template v-if="getPermission('dashboard:view')">
				<router-link
					:to="{ name: 'dashboard', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/dashboard.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Dashboard</span>
						</a>
					</li>
				</router-link>
			</template>

			<template v-if="getPermission('customer:view')">
				<router-link
					:to="{ name: 'customer', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/member.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Customers</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('property:view')">
				<router-link
					:to="{ name: 'property', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/location.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Property</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('supplier:view')">
				<router-link
					:to="{ name: 'vendor', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/vendor.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Suppliers</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('asset:view')">
				<router-link
					:to="{ name: 'asset', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/asset.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Assets</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('asset-stock:view')">
				<router-link
					:to="{ name: 'asset-stock', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/asset-stock.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Asset Stocks</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('perishables:view')">
				<router-link
					:to="{ name: 'inventory', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/inventory.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Perishables</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="false">
				<router-link
					:to="{ name: 'location', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/location.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Locations</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('service:view')">
				<router-link
					:to="{ name: 'services', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Services</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('checkout:view')">
				<router-link
					:to="{ name: 'checkedout', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<v-icon small left class="darken-4 text-white">mdi-share-outline</v-icon>
								<!-- <inline-svg :src="$assetURL('media/custom-svg/group.svg')" /> -->
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Issue</span>
						</a>
					</li>
				</router-link>
			</template>

			<template v-if="getPermission('checkin:view')">
				<router-link
					:to="{ name: 'checkin', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4 darken-4 text-white">
								<!--begin::Svg Icon-->
								<v-icon small left class="darken-4 text-white">mdi-share-outline mdi-flip-h</v-icon>
								<!-- <inline-svg :src="$assetURL('media/custom-svg/group.svg')" /> -->
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Receive</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('retire:view')">
				<router-link
					:to="{ name: 'retire', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4 darken-4 text-white">
								<!--begin::Svg Icon-->
								<v-icon small left class="darken-4 text-white">mdi-cancel</v-icon>
								<!-- <inline-svg :src="$assetURL('media/custom-svg/group.svg')" /> -->
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Retire</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('reservation:view')">
				<router-link
					:to="{ name: 'reservations', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/reservation.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Reservations</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('category:view')">
				<router-link
					:to="{ name: 'group', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/group.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Category</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('project:view')">
				<router-link
					:to="{ name: 'project', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/reservation.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Project</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('incharge-officer:view')">
				<router-link
					:to="{ name: 'member', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/member.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Staffs</span>
						</a>
					</li>
				</router-link>
			</template>
			<template v-if="getPermission('order:view')">
				<router-link
					:to="{ name: 'orders', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/member.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Orders</span>
						</a>
					</li>
				</router-link>
			</template>
			<router-link
				v-if="false"
				:to="{ name: 'report', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/report.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Reports</span>
					</a>
				</li>
			</router-link>
		</template>
	</ul>
</template>

<script>
import { isEmpty } from "lodash";
export default {
	name: "KTMenu",
	data() {
		return {
			menuList: [
				{
					getPermission: "dashboard:view",
					name: "dashboard",
					text: "Dashboard",
					icon: "media/custom-svg/dashboard.svg",
					child: false,
				},
				{
					getPermission: "Contacts:view",
					name: "contact",
					text: "Contact",
					icon: "media/custom-svg/member.svg",
					child: true,
					submenu: [
						{
							name: "customer",
							text: "Customers",
						},
						{
							name: "property",
							text: "Property",
						},
						{
							name: "vendor",
							text: "Suppliers",
						},
						{
							name: "member",
							text: "Staffs",
						},
					],
				},
				/* {
					getPermission: "customer:view",
					name: "customer",
					text: "Customers",
					icon: "media/custom-svg/member.svg",
					child: false,
				},
				{
					getPermission: "property:view",
					name: "property",
					text: "Property",
					icon: "media/custom-svg/location.svg",
					child: false,
				},
				{
					getPermission: "supplier:view",
					name: "vendor",
					text: "Suppliers",
					icon: "media/custom-svg/vendor.svg",
					child: false,
				}, */
				{
					getPermission: "Inevtories:view",
					name: "inevtories",
					text: "Inventory",
					icon: "media/custom-svg/inventory.svg",
					child: true,
					submenu: [
						{
							name: "asset",
							text: "Asset",
						},
						{
							name: "asset-stock",
							text: "Asset Stock",
						},
						{
							name: "inventory",
							text: "Perishables",
						},
					],
				},
				/* {
					getPermission: "asset:view",
					name: "asset",
					text: "Assets",
					icon: "media/custom-svg/asset.svg",
					child: false,
				},
				{
					getPermission: "asset-stock:view",
					name: "asset-stock",
					text: "Asset Stocks",
					icon: "media/custom-svg/asset-stock.svg",
					child: false,
				},
				{
					getPermission: "perishables:view",
					name: "inventory",
					text: "Perishables",
					icon: "media/custom-svg/inventory.svg",
					child: false,
				}, */
				{
					getPermission: "service:view",
					name: "services",
					text: "Services",
					icon: "media/custom-svg/services.svg",
					child: false,
				},
				{
					getPermission: "checkout:view",
					name: "checkedout",
					text: "Transactions",
					icon: "media/custom-svg/transaction.svg",
					child: false,
				},
				{
					getPermission: "rental:view",
					name: "rental",
					text: "Sales/Rental",
					icon: "media/custom-svg/Group-8886.svg",
					child: false,
				},
				/* {
					getPermission: "checkin:view",
					name: "checkin",
					text: "Receive",
					icon: "media/custom-svg/group.svg",
					child: false,
				}, */
				{
					getPermission: "retire:view",
					name: "retire",
					text: "Retire",
					icon: "media/custom-svg/group.svg",
					child: false,
				},
				{
					getPermission: "chreservation:view",
					name: "reservations",
					text: "Reservations",
					icon: "media/custom-svg/reservation.svg",
					child: false,
				},
				/* {
					getPermission: "category:view",
					name: "group",
					text: "Category",
					icon: "media/custom-svg/group.svg",
					child: false,
				}, */
				{
					getPermission: "project:view",
					name: "project",
					text: "Project",
					icon: "media/custom-svg/reservation.svg",
					child: false,
				},
				/* {
					getPermission: "incharge-officer:view",
					name: "member",
					text: "Staffs",
					icon: "media/custom-svg/member.svg",
					child: false,
				}, */
				{
					getPermission: "order:view",
					name: "orders",
					text: "Records",
					icon: "media/custom-svg/group.svg",
					child: false,
				},
				{
					getPermission: "document:view",
					name: "documents",
					text: "Documents",
					icon: "media/custom-svg/files.svg",
					child: false,
				},
				{
					getPermission: "warranty:view",
					name: "warranty",
					text: "Warranty",
					icon: "media/custom-svg/warranty.svg",
					child: false,
				},
				/* {
					getPermission: "calendar:view",
					name: "calendar",
					text: "Calendar",
					icon: "media/custom-svg/files.svg",
					child: false,
				}, */
				{
					getPermission: "damage:view",
					name: "damage",
					text: "Support",
					icon: "media/custom-svg/damege.svg",
					child: false,
				},
				/*{
					getPermission: "report:view",
					name: "report",
					text: "Reports",
					icon: "media/custom-svg/report.svg",
					child: true,
					submenu: [
						{
							name: "asset-report",
							text: "Asset",
						},
						{
							name: "assetStock-report",
							text: "Asset Stock",
						},
						{
							name: "project-report",
							text: "Asset By Project",
						},
					],
				},*/
			],
		};
	},
	methods: {
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		},
		isRouteActive(match) {
			if (isEmpty(this.$route.meta) === false) {
				return this.$route.meta.menuActive === match;
			}
			return false;
		},
		isChildActive(row) {
			let isActive = false;
			for (let i = 0; i < row.length; i++) {
				isActive = this.isRouteActive(row[i].menu);
				if (isActive) {
					return isActive;
				}
			}
			return isActive;
		},
	},
};
</script>
